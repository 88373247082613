function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/user/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const userRoutes = [
  {
    path: '/user/dashboard',
    component: view('Dashboard'),
    name: 'user.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/my-bookings',
    component: view('MyBookings'),
    name: 'user.my-bookings',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'My Bookings',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/booking-calendar',
    component: view('BookingCalendar'),
    name: 'user.booking-calendar',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Booking Calendar',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/booking-histories',
    component: view('BookingHistories'),
    name: 'user.booking-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Booking Histories',
          active: true
        }
      ]
    }
  }
]

export default userRoutes
