function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const adminRoutes = [
  {
    path: '/admin/dashboard',
    component: view('Dashboard'),
    name: 'admin.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/booking-calendar',
    component: view('BookingCalendar'),
    name: 'admin.booking-calendar',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Booking Calendar',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/booking-list',
    component: view('Bookings'),
    name: 'admin.booking-list',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Bookings',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/companies',
    component: view('Companies'),
    name: 'admin.companies',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Companies',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/organizations',
    component: view('Organizations'),
    name: 'admin.organizations',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Organizations',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/venues',
    component: view('Venues'),
    name: 'admin.venues',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Venues',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/items',
    component: view('Items'),
    name: 'admin.items',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Items',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/user-accounts',
    component: view('UserAccounts'),
    name: 'admin.user-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'User Accounts',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/employee-accounts',
    component: view('EmployeeAccounts'),
    name: 'admin.employee-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Employee Accounts',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/mailing-lists',
    component: view('MailingList'),
    name: 'admin.mailing-lists',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Mailing Lists',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/reports/booking-histories',
    component: view('BookingHistories'),
    name: 'admin.booking-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Booking Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/reports/by-items',
    component: view('ReportByItems'),
    name: 'admin.report-by-items',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'By Items',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/reports/by-users',
    component: view('ReportByUsers'),
    name: 'admin.report-by-users',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'By Users',
          active: true
        }
      ]
    }
  }

]

export default adminRoutes
