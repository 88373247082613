import ApiService from '@/services/core/api.service'

const ListService = {
  async getCompanyList () {
    return ApiService.get('shared/list/companies')
  },

  async getOrganizationList (companyId = '') {
    return ApiService.get(`shared/list/organizations?company_id=${companyId}`)
  },

  async getItemList (query) {
    return ApiService.get(`shared/list/items?${query}`)
  },

  async getVenueList () {
    return ApiService.get('shared/list/venues')
  },

  async getUserList () {
    return ApiService.get('shared/list/users')
  }
}

export default ListService
