import ApiService from '@/services/core/api.service'

const AdminDashboardService = {
  async getOrganizationPerItems (query) {
    return ApiService.get(`admin/get-organization-per-items?${query}`)
  },

  async getTotalBookedPerUser (query) {
    return ApiService.get(`admin/total-booked-per-user?${query}`)
  }
}

export default AdminDashboardService
