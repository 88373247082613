import ApiService from '@/services/core/api.service'

const VenueService = {
  async get (query) {
    return ApiService.get(`admin/venues?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/venues', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/venues/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`admin/venues/${payload.id}`)
  }
}

export default VenueService
