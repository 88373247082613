
import ApiService from '@/services/core/api.service'

const CalendarService = {
  async getCalendarEventList (query) {
    return ApiService.get(`shared/list/booking-calendar-events?${query}`)
  }
}

export default CalendarService
