import ApiService from '@/services/core/api.service'

const BookingService = {
  async get (query) {
    return ApiService.get(`user/bookings?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/bookings', payload)
  },

  async put (payload) {
    return ApiService.put(`user/bookings/${payload.id}`, payload)
  }
}

export default BookingService
