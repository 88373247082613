import ApiService from '@/services/core/api.service'

const ReportService = {
  async getBookingHistories (query) {
    return ApiService.get(`admin/reports/booking-histories?${query}`)
  },

  async getItemReport1 (query) {
    return ApiService.get(`admin/reports/report-1?${query}`)
  },

  async getUserReport2 (query) {
    return ApiService.get(`admin/reports/report-2?${query}`)
  }
}

export default ReportService
